import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $(".the-page").ready(function () {
      doIt();

      $('#user_setting_override_site_mode').change(function(){
        doIt();
      });
      $('#user_setting_wine_event').change(function(){
        doIt();
      });

      $('#site_setting_override_site_mode').change(function(){
        doIt();
      });
      $('#site_setting_wine_event').change(function(){
        doIt();
      });

    });
  }
}

function doIt() {
  if ($("#user_setting_override_site_mode").prop("checked") === true) {
    $("#user_setting_site_mode").prop("disabled", false);
    $("label[for=user_setting_site_mode]").prop("disabled", false);
    $('label[for=user_setting_site_mode]').removeClass('disabled-label');
    $('#user_setting_site_mode').removeClass('disabled-label');
  } else {
    $("#user_setting_site_mode").prop("disabled", true);
    $("label[for=user_setting_site_mode]").prop("disabled", true);
    $('label[for=user_setting_site_mode]').addClass('disabled-label');
    $('#user_setting_site_mode').addClass('disabled-label');
  }

  if ($("#user_setting_wine_event").prop("checked") === true) {
    $("#user_setting_wine_event_full").prop("disabled", false);
  } else {
    $("#user_setting_wine_event_full").prop("disabled", true);
  }

  if ($("#site_setting_wine_event").prop("checked") === true) {
    $("#site_setting_wine_event_full").prop("disabled", false);
  } else {
    $("#site_setting_wine_event_full").prop("disabled", true);
  }

}