import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    initialize() {
        // console.log("=======>>>>>> initialize() <<<<<<=======")

        this.boundNumberSpinnerButtonEvent = this.numberSpinnerButtonEvent.bind(this);
        this.boundCheckoutButtonEvent = this.checkoutButtonEvent.bind(this);
        this.boundBackToOrderButtonEvent = this.backToOrderButtonEvent.bind(this);

        document.querySelector("#check-out-button").addEventListener("click", this.boundCheckoutButtonEvent);
        document.querySelector("#entry_fee_plus_btn").addEventListener("click", this.boundNumberSpinnerButtonEvent);
        document.querySelector("#back-to-order-button").addEventListener("click", this.boundBackToOrderButtonEvent);

        document.querySelector("#entry_fee_minus_btn").addEventListener("click", this.boundNumberSpinnerButtonEvent);
        document.querySelector("#entry_fee_plus_btn").addEventListener("click", this.boundNumberSpinnerButtonEvent);

        $('input[type="checkbox"][id$="bowler_paying_1_check_box"]').change(function () {
            toggleBowlerPayingNow(this);
        });

        $('input[type="checkbox"][id$="bowler_paying_2_check_box"]').change(function () {
            toggleBowlerPayingNow(this);
        });

        $('input[type="checkbox"][id$="bowler_paying_3_check_box"]').change(function () {
            toggleBowlerPayingNow(this);
        });

        $('input[type="checkbox"][id$="bowler_paying_4_check_box"]').change(function () {
            toggleBowlerPayingNow(this);
        });
    }

    disconnect() {
        // console.log("=======>>>>>> disconnect() <<<<<<=======")
    }

    connect() {
        // console.log("=======>>>>>> connect() <<<<<<=======")

        $(".the-page").ready(function () {
            init();
        });
    }

    numberSpinnerButtonEvent(event) {
        var btn = $(event.currentTarget), newVal = 0;

        var item_key = btn.attr("id");
        var foo = item_key.replace("_minus_btn", "");
        var goo = foo.replace("_plus_btn", "");
        var quantity_id = "#" + goo + "_quantity";

        var oldValue = $(quantity_id).html().trim();

        // Enforce range 0 - 9
        if (btn.attr('data-dir') === 'up') {
            if (oldValue < 9) {
                newVal = parseInt(oldValue) + 1;
            } else {
                newVal = 9;
            }
        } else {
            if (oldValue > 1) {
                newVal = parseInt(oldValue) - 1;
            } else {
                newVal = 0;
            }
        }

        $(quantity_id).html(newVal);
        update_cost(btn);
        enableButtons();
    }

    checkoutButtonEvent(event) {
        // console.log("checkout-button pressed");

        let isValid = validateCheckoutButtonClicked();
        if (!isValid) {
            return;
        }

        hide_quantity_buttons();

        $("#new-cart").addClass("hidden");
        $("#preview-cart").removeClass("hidden");
        $("#preview-cart-paypal").removeClass("hidden");
        $("#paypal-button-container").removeClass("hidden");
        $("#paypal-pay-buttons").removeClass("hidden");

        $("#order-form").addClass("hidden");
        $("#order-form-instructions").addClass("hidden");
        $("#invoice").removeClass("hidden");
        $("#invoice-instructions").removeClass("hidden");

        $("#cart-team-selection").addClass("hidden");
        $("#cart-team-selection-invoice").removeClass("hidden");

        $("#bowler-list").addClass("hidden");
        $('#team-members-paying-now-list').text(getTeamMembersPayingNowText());
        $("#bowler-list-invoice").removeClass("hidden");

        // setTeamActionInvoice();

        // let prePopTeamName = hasPrePopulatedData()["team_name"];
        // if (prePopTeamName) {
        //     setTeamActionInvoicePrePop();
        // } else {
        //     setTeamActionInvoice();
        // }

        document.getElementById("paypal-button-container").innerHTML = "";

        window.scrollTo({top: $("#invoice").offset().top, behavior: "smooth"});

        paypal.Buttons({
            style: {
                shape: 'rect',
                color: 'gold',
                layout: 'vertical',
                label: 'pay',

            },

            createOrder: function (data, actions) {
                return actions.order.create(getPayPalOrder());
                // return actions.order.create({
                //     purchase_units: [{"description": "igbo desc", "amount": {"currency_code": "USD", "value": 1}}]
                // });
            },

            onApprove: function(data, actions) {
                // show loader on screen whilst waiting to redirect
                // $('.checkout-loader').addClass('active');
                $("#order-page-invoice").addClass("hidden");

                actions.order.capture().then(function(details) {
                    $("#page-loading-id").removeClass("hidden");

                    // Show confirmation page
                    // $("#order-page-invoice").addClass("hidden");
                    // $("#order-page-success").removeClass("hidden");

                    const orderDetails = getOrderDetails();

                    orderDetails['order'] = data;
                    orderDetails['details'] = details;

                    // Call your server to validate and capture the transaction
                    return fetch('/orders/paypal', {
                        method: 'post',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(orderDetails)
                    }).then(function(response){
                        $("#page-loading-id").addClass("hidden");

                        // redirect to the completed page if paid
                        window.location.href = '/orders/thank_you_wine';
                    });
                });
            },

            onError: function (err) {
                // console.log(err);
            }
        }).render('#paypal-button-container');

    }

    backToOrderButtonEvent(event) {
        show_quantity_buttons();

        $("#new-cart").removeClass("hidden");
        $("#preview-cart").addClass("hidden");
        $("#preview-cart-paypal").addClass("hidden");
        $("#paypal-pay-buttons").addClass("hidden");
        $("#paypal-button-container").addClass("hidden");

        $("#order-form").removeClass("hidden");
        $("#order-form-instructions").removeClass("hidden");
        $("#invoice").addClass("hidden");
        $("#invoice-instructions").addClass("hidden");

        $("#cart-team-selection").removeClass("hidden");
        $("#cart-team-selection-invoice").addClass("hidden");

        $("#bowler-list").removeClass("hidden");
        $("#bowler-list-invoice").addClass("hidden");

        window.scrollTo({top: $("#order-form").offset().top, behavior: "smooth"});

        const pre_popping = !($('#bowler_paying_1_check_box_id').hasClass("hidden"));
        if (!pre_popping) {
            $("#team-selection-area-instructions").removeClass("hidden");
        }

        $("#team-pay-action").addClass("hidden");
    }
}

function hasPrePopulatedData() {
    let prepop_data_json = $('#has_pre_populated_data').val();
    if (prepop_data_json.length > 0) {
        // console.log("JSON parsing str: " + prepop_data_json);
        return JSON.parse(prepop_data_json);
    }
    return JSON.parse("{}");
}

// function showValuesInObject(obj) {
//     console.log(Object.keys(obj));
//
//     console.log(obj);
//     console.log ("obj['team'] = " + obj['team']);
//
//     // let keys = Object.keys(obj);
//     for (let key in obj) {
//         if (obj.hasOwnProperty(key)) {
//             // console.log("found " + obj[key]);
//         }
//     }
// }

function runSiteAs() {
    return $('#run-site-as').val();
}

function igboSite() {
    return runSiteAs() === 'igbo';
}

function espressoSite() {
    return runSiteAs() === 'espresso';
}

function init() {
    // console.log("init orders page");

    let prepop_data = hasPrePopulatedData();
    // console.log("prepop_data = " + prepop_data);

    let bowler_1_name = prepop_data["bowler_1_name"];
    let bowler_2_name = prepop_data["bowler_2_name"];
    let bowler_3_name = prepop_data["bowler_3_name"];
    let bowler_4_name = prepop_data["bowler_4_name"];

    let pre_populate = prepop_data["bowler_ids"];

    if (pre_populate) {
        // console.log("pre populate yes");

        if (bowler_1_name != null && bowler_1_name.length > 0) {
            $("#bowler_paying_1").val(bowler_1_name);
            $("#bowler_paying_1_check_box").prop('checked', true);
        } else {
            $("#bowler_paying_1_check_box").prop('checked', false);
            $('input[type="text"][id$="bowler_paying_1"]').prop('disabled', true);
        }
        if (bowler_2_name != null && bowler_2_name.length > 0) {
            $("#bowler_paying_2").val(bowler_2_name);
            $("#bowler_paying_2_check_box").prop('checked', true);
        } else {
            $("#bowler_paying_2_check_box").prop('checked', false);
            $('input[type="text"][id$="bowler_paying_2"]').prop('disabled', true);
        }
        if (bowler_3_name != null && bowler_3_name.length > 0) {
            $("#bowler_paying_3").val(bowler_3_name);
            $("#bowler_paying_3_check_box").prop('checked', true);
        } else {
            $("#bowler_paying_3_check_box").prop('checked', false);
            $('input[type="text"][id$="bowler_paying_3"]').prop('disabled', true);
        }
        if (bowler_4_name != null && bowler_4_name.length > 0) {
            $("#bowler_paying_4").val(bowler_4_name);
            $("#bowler_paying_4_check_box").prop('checked', true);
        } else {
            $("#bowler_paying_4_check_box").prop('checked', false);
            $('input[type="text"][id$="bowler_paying_4"]').prop('disabled', true);
        }

        // Populate Entry Fee
        $("#entry_fee_quantity").html(prepop_number_of_entries);
        let price = $("#entry_fee_price").html();
        let cost = price * prepop_number_of_entries;
        $("#entry_fee_cost").html(cost.toFixed(2));

        update_total();

        // Not pre-pop
    } else {
        $('#bowler_paying_1_check_box_id').addClass("hidden");
        $('#bowler_paying_2_check_box_id').addClass("hidden");
        $('#bowler_paying_3_check_box_id').addClass("hidden");
        $('#bowler_paying_4_check_box_id').addClass("hidden");
    }

    $("#order-form").removeClass("hidden");
    $("#invoice").addClass("hidden");

    enableButtons();
    toggleBowlerPayingNow();

    // $("#page-loading-id").addClass("hidden");
    $("#full-page").addClass("hidden");
}

function update_cost(btn) {
    var item_key = btn.attr("id");

    var foo = item_key.replace("_minus_btn", "");
    var goo = foo.replace("_plus_btn", "");
    var quantity_id = "#" + goo + "_quantity";

    var quantity = $(quantity_id).html().trim();

    var price_id = "#" + goo + "_price";
    var cost_id = "#" + goo + "_cost";

    var price = $(price_id).html();
    var cost = price * quantity;

    $(cost_id).html(cost.toFixed(2));

    update_total();
}

function update_total() {
    let items = getCartItemList();

    let total_cost = 0;
    items.forEach(function (item) {
        var item_cost = $("#" + item + "_cost").html().trim();
        total_cost = total_cost + parseInt(item_cost, 10);
    });

    $('#order-total-cost-id').val(total_cost);

    var span = document.getElementById("order-total-cost-id");
    span.innerText = "" + total_cost.toFixed(2);

    // TODO update pdf_total
}

function getItemQuantity() {
    let itemQuantity = {};

    itemQuantity["entry_fee"] = $("#entry_fee_quantity").html().trim();

    return itemQuantity;
}

function getPrices() {
    let itemPrice = {};

    itemPrice["entry_fee"] = $("#entry_fee_price").html().trim();

    return itemPrice;
}

function getCosts() {
    let itemCost = {};

    itemCost["entry_fee"] = $("#entry_fee_cost").html().trim();

    return itemCost;
}

function getOrderedItems() {

    let return_obj = {};
    return_obj["quantity"] = getItemQuantity();
    return_obj["price"] = getPrices();
    return_obj["cost"] = getCosts();

    return return_obj;
}

function getCartItemList() {
    let items = [
        "entry_fee"
    ];

    return items;
}

function hide_quantity_buttons() {
    let items = getCartItemList();

    items.forEach(function (item) {
        let show = $("#" + item + "_quantity").html().trim();
        if (show === "0") {
            $("#" + item + "_item_row").addClass("tablet-row-hidden");
        } else {
            $("#" + item + "_minus_btn").hide();
            $("#" + item + "_plus_btn").hide();
        }
    });
}

function show_quantity_buttons() {
    let items = [
        "entry_fee"
    ];

    items.forEach(function (item) {
        $("#" + item + "_item_row").removeClass("tablet-row-hidden");
        $("#" + item + "_minus_btn").show();
        $("#" + item + "_plus_btn").show();
    });
}

function update_hidden_quantities() {
    let items = [
        "entry_fee"
    ];

    items.forEach(function (item) {
        var item_quantity_id = "#" + item + "_quantity";
        var paypal_quantity_id = "input[type=hidden]#" + item + "_paypal_quantity";

        var item_quantity = $(item_quantity_id).html().trim();
        $(paypal_quantity_id).val(item_quantity);
    });
}

function validateCheckoutButtonClicked() {

    let bowler_1_disabled = $('input[type="text"][id$="bowler_paying_1"]').prop('disabled');
    let bowler_2_disabled = $('input[type="text"][id$="bowler_paying_2"]').prop('disabled');
    let bowler_3_disabled = $('input[type="text"][id$="bowler_paying_3"]').prop('disabled');
    let bowler_4_disabled = $('input[type="text"][id$="bowler_paying_4"]').prop('disabled');

    let bowler_1_name = $('input[type="text"][id$="bowler_paying_1"]').val();
    let bowler_2_name = $('input[type="text"][id$="bowler_paying_2"]').val();
    let bowler_3_name = $('input[type="text"][id$="bowler_paying_3"]').val();
    let bowler_4_name = $('input[type="text"][id$="bowler_paying_4"]').val();

    let bowlersValid = false;

    if (!bowler_1_disabled && bowler_1_name.length > 0)  {
        bowlersValid = true;
    }
    if (!bowler_2_disabled && bowler_2_name.length > 0)  {
        bowlersValid = true;
    }
    if (!bowler_3_disabled && bowler_3_name.length > 0)  {
        bowlersValid = true;
    }
    if (!bowler_4_disabled && bowler_4_name.length > 0)  {
        bowlersValid = true;
    }

    if (!bowlersValid) {
        $('#order-entry-member-paying-now').addClass("red-error-label");
    } else {
        $('#order-entry-member-paying-now').removeClass("red-error-label");
    }

    return bowlersValid;
}

function get_items() {
    let items = {};

    items["entry_fee"] = 'Wine Tour';

    return items;
}

function getInvoice() {

    let items = [
        "entry_fee"
    ];

    let invoice = "";
    let the_items = get_items();
    let item_quantities = getItemQuantity();
    let prices = getPrices();
    let costs = getCosts();

    items.forEach(function (item) {
        if (item_quantities[item] > 0) {
            let line_item = item_quantities[item] + " " + the_items[item] + " @ " + prices[item] + " each = " + costs[item];
            invoice = invoice + line_item + "\n";
        }
    });

    return invoice;
}

function addTeamMembersToOrder(addToItems) {

    let bowler_paying_1 = $("#bowler_paying_1").val().slice(0, 50);
    if (bowler_paying_1 != "") {
        bowler_paying_1 = "Bowler: " + bowler_paying_1;
        addToItems.push(
            {
                name: bowler_paying_1,
                description: "",
                unit_amount: {
                    currency_code: "USD",
                    value: 0
                },
                quantity: 1,
                category: "DIGITAL_GOODS"
            }
        );
    }

    let bowler_paying_2 = $("#bowler_paying_2").val().slice(0, 50);
    if (bowler_paying_2 != "") {
        bowler_paying_2 = "Bowler: " + bowler_paying_2;
        addToItems.push(
            {
                name: bowler_paying_2,
                description: "bowler paying now",
                unit_amount: {
                    currency_code: "USD",
                    value: 0
                },
                quantity: 1,
                category: "DIGITAL_GOODS"
            }
        );
    }

    let bowler_paying_3 = $("#bowler_paying_3").val().slice(0, 50);
    if (bowler_paying_3 != "") {
        bowler_paying_3 = "Bowler: " + bowler_paying_3;
        addToItems.push(
            {
                name: bowler_paying_3,
                description: "bowler paying now",
                unit_amount: {
                    currency_code: "USD",
                    value: 0
                },
                quantity: 1,
                category: "DIGITAL_GOODS"
            }
        );
    }

    let bowler_paying_4 = $("#bowler_paying_4").val().slice(0, 50);
    if (bowler_paying_4 != "") {
        bowler_paying_4 = "Bowler: " + bowler_paying_4;
        addToItems.push(
            {
                name: bowler_paying_4,
                description: "bowler paying now",
                unit_amount: {
                    currency_code: "USD",
                    value: 0
                },
                quantity: 1,
                category: "DIGITAL_GOODS"
            }
        );
    }

}

function addItemsToOrder(addToItems) {

    let items = [
        "entry_fee"
    ];

    let invoice = "";
    let the_items = get_items();
    let item_quantities = getItemQuantity();
    let prices = getPrices();
    let costs = getCosts();

    items.forEach(function (item, index) {
        if (item_quantities[item] > 0) {
            let line_item = item_quantities[item] + " " + the_items[item] + " @ " + prices[item] + " each = " + costs[item];
            invoice = invoice + line_item + "\n";

            addToItems.push(
                {
                    name: the_items[item],
                    description: "",
                    unit_amount: {
                        currency_code: "USD",
                        value: prices[item]
                    },
                    quantity: item_quantities[item],
                    category: "DIGITAL_GOODS"
                }
            );

        }
    });
}

function getPayPalOrder() {

    let totalCost = $('#order-total-cost-id').html();

    let soft_descriptor = getInvoice();

    let description = "01234567890123456789012345678901234567890123456789";
    soft_descriptor = "0123456789012345678901";  // MAX LENGTH IS 22 chars

    let ret = {
        purchase_units: []
    };

    let items = [];

    addItemsToOrder(items);

    addTeamMembersToOrder(items);

    ret.purchase_units.push({
        description: "IGBO 2023",
        amount: {
            currency_code: "USD",
            value: totalCost,
            breakdown: {
                item_total: {
                    currency_code: "USD",
                    value: totalCost
                }
            }
        },
        items: items
    });

    // console.log("paypal order = " + JSON.stringify(ret));

    return ret;
}

function getOrderDetails() {
    let order = getOrderedItems();

    const payTeamChecked = $('input[type="radio"][id$="team_action_team_action_members"]').is(':checked');
    const selectedTeam = $('#team_action_team_to_pay option:selected').text();

    order["paying_now"] = $("#team-members-paying-now-list").html().trim();

    order["total"] = parseInt($('#order-total-cost-id').html().trim());

    return order;
}

function setTeamActionInvoicePrePop() {

    // Add bowlers paying now:
    $('#team-members-paying-now-list').text(getTeamMembersPayingNowText());
}

function setTeamActionInvoice() {
    var ele = document.getElementsByName('team_action[team_action]');

    for (i = 0; i < ele.length; i++) {
        if (ele[i].checked) {
            var option_selected = ele[i].value;

            if (option_selected == "members") {
                var team_selected = $('#team_action_team_to_pay').find(":selected").text();
                $('#team-pay-action-team-name').text(team_selected);
                $("#team-pay-action-pick").addClass("hidden");
                $("#team-pay-action-unknown").addClass("hidden");
                $("#team-pay-action-noteam").addClass("hidden");
                $("#team-pay-action-team").removeClass("hidden");

            } else if (option_selected == "unknown") {
                $("#team-pay-action-team").addClass("hidden");
                $("#team-pay-action-pick").addClass("hidden");
                $("#team-pay-action-noteam").addClass("hidden");
                $("#team-pay-action-unknown").removeClass("hidden");

            } else if (option_selected == "pick") {
                $("#team-pay-action-team").addClass("hidden");
                $("#team-pay-action-unknown").addClass("hidden");
                $("#team-pay-action-noteam").addClass("hidden");
                $("#team-pay-action-pick").removeClass("hidden");

            } else if (option_selected == "noteam") {
                $("#team-pay-action-team").addClass("hidden");
                $("#team-pay-action-unknown").addClass("hidden");
                $("#team-pay-action-pick").addClass("hidden");
                // $("#team-pay-action-noteam").removeClass("hidden");
            }
        }
    }

    $('#team-members-paying-now-list').text(getTeamMembersPayingNowText());
}

function getTeamMembersPayingNowText() {

    let bowler_paying_1 = $("#bowler_paying_1").val().trim();
    let bowler_paying_2 = $("#bowler_paying_2").val().trim();
    let bowler_paying_3 = $("#bowler_paying_3").val().trim();
    let bowler_paying_4 = $("#bowler_paying_4").val().trim();

    let bowlers = [];

    const pre_popping = !($('#bowler_paying_1_check_box_id').hasClass("hidden"));

    if (pre_popping) {
        const bowler1checked = $('#bowler_paying_1_check_box').prop("checked");
        const bowler2checked = $('#bowler_paying_2_check_box').prop("checked");
        const bowler3checked = $('#bowler_paying_3_check_box').prop("checked");
        const bowler4checked = $('#bowler_paying_4_check_box').prop("checked");

        if (bowler1checked && bowler_paying_1 !== "") {
            bowlers.push(bowler_paying_1);
        }
        if (bowler2checked && bowler_paying_2 !== "") {
            bowlers.push(bowler_paying_2);
        }
        if (bowler3checked && bowler_paying_3 !== "") {
            bowlers.push(bowler_paying_3);
        }
        if (bowler4checked && bowler_paying_4 !== "") {
            bowlers.push(bowler_paying_4);
        }
    } else {
        if (bowler_paying_1 !== "") {
            bowlers.push(bowler_paying_1);
        }
        if (bowler_paying_2 !== "") {
            bowlers.push(bowler_paying_2);
        }
        if (bowler_paying_3 !== "") {
            bowlers.push(bowler_paying_3);
        }
        if (bowler_paying_4 !== "") {
            bowlers.push(bowler_paying_4);
        }
    }

    let returnText = "";
    bowlers.forEach(function (name) {
        returnText = returnText + name + ", ";
    });

    return returnText.slice(0, returnText.length - 2);
}

function toggleBowlerPayingNow() {

    const bowler1checked = $('#bowler_paying_1_check_box').prop("checked");
    $('input[type="text"][id$="bowler_paying_1"]').prop('disabled', !(bowler1checked));
    if (bowler1checked) {
        $('input[type="text"][id$="bowler_paying_1"]').removeClass('disabled-text-input');
    } else {
        $('input[type="text"][id$="bowler_paying_1"]').addClass('disabled-text-input');
    }

    const bowler2checked = $('#bowler_paying_2_check_box').prop("checked");
    $('input[type="text"][id$="bowler_paying_2"]').prop('disabled', !(bowler2checked));
    if (bowler2checked) {
        $('input[type="text"][id$="bowler_paying_2"]').removeClass('disabled-text-input');
    } else {
        $('input[type="text"][id$="bowler_paying_2"]').addClass('disabled-text-input');
    }

    const bowler3checked = $('#bowler_paying_3_check_box').prop("checked");
    $('input[type="text"][id$="bowler_paying_3"]').prop('disabled', !(bowler3checked));
    if (bowler3checked) {
        $('input[type="text"][id$="bowler_paying_3"]').removeClass('disabled-text-input');
    } else {
        $('input[type="text"][id$="bowler_paying_3"]').addClass('disabled-text-input');
    }

    const bowler4checked = $('#bowler_paying_4_check_box').prop("checked");
    $('input[type="text"][id$="bowler_paying_4"]').prop('disabled', !(bowler4checked));
    if (bowler4checked) {
        $('input[type="text"][id$="bowler_paying_4"]').removeClass('disabled-text-input');
    } else {
        $('input[type="text"][id$="bowler_paying_4"]').addClass('disabled-text-input');
    }

    enableButtons();
}

function enableButtons() {
    // const pre_popping = !($('#bowler_paying_1_check_box_id').hasClass("hidden"));
    //
    let disabled = false;

    let totalItems = 0;

    let itemQuantity = getItemQuantity();

    Object.keys(itemQuantity).forEach(function (key) {
        totalItems = totalItems + parseInt(itemQuantity[key]);
    })

    // console.log("totalItems = " + totalItems);

    disabled = totalItems === 0;

    // if (
    //     ordered_items["entry_fee"] === 0 &&
    //     ordered_items["optional_scratch"] === 0 &&
    //     // ordered_items["no_tap_social"] === 0 &&
    //     ordered_items["rainbow_packs_A"] === 0 &&
    //     ordered_items["rainbow_packs_B"] === 0 &&
    //     ordered_items["rainbow_packs_C"] === 0 &&
    //     ordered_items["rainbow_packs_D"] === 0 &&
    //
    //     ordered_items["shirt_size_S"] === 0 &&
    //     ordered_items["shirt_size_M"] === 0 &&
    //     ordered_items["shirt_size_L"] === 0 &&
    //     ordered_items["shirt_size_XL"] === 0 &&
    //     ordered_items["shirt_size_2XL"] === 0 &&
    //     ordered_items["shirt_size_3XL"] === 0 &&
    //
    //     ordered_items["scratch_masters_A"] === 0 &&
    //     ordered_items["scratch_masters_B"] === 0 &&
    //     ordered_items["scratch_masters_C"] === 0 &&
    //     ordered_items["scratch_masters_D"] === 0 &&
    //     ordered_items["scratch_masters_E"] === 0 &&
    //     ordered_items["non_participant"] === 0
    // ) {
    //     disabled = true;
    // }

    // console.log("enableButtons: disabled = " + disabled);

    $('#check-out-button').prop('disabled', disabled);
}
