import {Controller} from "@hotwired/stimulus"
import alert from "bootstrap/js/src/alert";

export default class extends Controller {

    initialize() {
        this.boundDoublesPartnerSelectionChangeListener = this.doublesPartnerSelectionChangeListener.bind(this);
        document.querySelector("#bowlers_0_first_name").addEventListener("change", this.boundDoublesPartnerSelectionChangeListener);
        document.querySelector("#bowlers_0_last_name").addEventListener("change", this.boundDoublesPartnerSelectionChangeListener);
        document.querySelector("#bowlers_1_first_name").addEventListener("change", this.boundDoublesPartnerSelectionChangeListener);
        document.querySelector("#bowlers_1_last_name").addEventListener("change", this.boundDoublesPartnerSelectionChangeListener);
        document.querySelector("#bowlers_2_first_name").addEventListener("change", this.boundDoublesPartnerSelectionChangeListener);
        document.querySelector("#bowlers_2_last_name").addEventListener("change", this.boundDoublesPartnerSelectionChangeListener);
        document.querySelector("#bowlers_3_first_name").addEventListener("change", this.boundDoublesPartnerSelectionChangeListener);
        document.querySelector("#bowlers_3_last_name").addEventListener("change", this.boundDoublesPartnerSelectionChangeListener);

        this.boundDoublesPartnerSelectionMade = this.doublesPartnerSelectionMade.bind(this);
        document.querySelector("#doubles_partner_position_1").addEventListener("change", this.boundDoublesPartnerSelectionMade);
        document.querySelector("#doubles_partner_position_2").addEventListener("change", this.boundDoublesPartnerSelectionMade);
        document.querySelector("#doubles_partner_position_3").addEventListener("change", this.boundDoublesPartnerSelectionMade);
        document.querySelector("#doubles_partner_position_4").addEventListener("change", this.boundDoublesPartnerSelectionMade);

        $(".the-page").ready(function () {

            init();
            initBowlersForm();

            let add_button_div = "#add_bowler_entry_form_div_0";
            $(add_button_div).removeClass('hidden');

            initialize_add_bowler(0);
            initialize_add_bowler(1);
            initialize_add_bowler(2);
            initialize_remove_bowler(1);
            initialize_remove_bowler(2);
            initialize_remove_bowler(3);

        });
    }

    doublesPartnerSelectionChangeListener(changeEvent) {
        // console.log("doublesPartnerSelectionChangeListener("+changeEvent+")");

        update_partners_selections(changeEvent);
    }

    doublesPartnerSelectionMade(arg1) {
        // console.log(">>>>>>>>>>>   doublesPartnerSelectionMade()");

        // console.log("bind doublesPartnerSelectionMade arg1 = " + arg1);
        // console.log("event.target = " + arg1.target);
        // console.log("event.target = " + arg1.target.name);

        match_doubles_partner_selections(arg1);
    }
}

function initialize_add_bowler(bowler_index) {
    // console.log(">>>>>>>>>>>   initialize_add_bowler()");

    let add_button = "#add_bowler_entry_form_" + bowler_index;
    let add_button_div = "#add_bowler_entry_form_div_" + bowler_index;
    let add_button_div_next = "#add_bowler_entry_form_div_" + (bowler_index + 1);
    let remove_button_div_next = "#remove_bowler_entry_form_div_" + (bowler_index + 1);
    let remove_button_div = "#remove_bowler_entry_form_div_" + bowler_index;
    let bowler_card_next = "#bowler_card_" + (bowler_index + 1);

    $(add_button).click(function () {
        $(add_button_div).addClass('hidden');
        if (bowler_index < 2) {
            $(add_button_div_next).removeClass('hidden');
        }
        $(remove_button_div_next).removeClass('hidden');
        $(remove_button_div).addClass('hidden')
        $(bowler_card_next).removeClass('hidden');

        // let existingBowlers = getExistingBowlerCount();
        // if (existingBowlers >= 3) {
        //     $(add_button_div_next).removeClass('invisible');
        // }

        setBowlerCardShowing((bowler_index + 1), "true");
        setBowlerRequired((bowler_index + 1), true);
        // updateDoublesPartner(bowler_index, "add");

        // $("#doubles-partner-selection-card").removeClass("hidden");
        update_partners_selections(null);

        window.scrollTo({top: $(bowler_card_next).offset().top, behavior: 'smooth'});
    });
}

function initialize_remove_bowler(bowler_index) {
    // console.log(">>>>>>>>>>>   initialize_remove_bowler()");

    let remove_button = "#remove_bowler_entry_form_" + bowler_index;
    let add_button_div_previous = "#add_bowler_entry_form_div_" + (bowler_index - 1);
    let add_button_div = "#add_bowler_entry_form_div_" + bowler_index;
    let remove_button_div = "#remove_bowler_entry_form_div_" + bowler_index;
    let remove_button_div_previous = "#remove_bowler_entry_form_div_" + (bowler_index - 1);
    let bowler_card = "#bowler_card_" + bowler_index;
    let bowler_card_previous = "#bowler_card_" + (bowler_index - 1);

    $(remove_button).click(function () {
        $(add_button_div_previous).removeClass('hidden');
        $(add_button_div).addClass('hidden');
        $(remove_button_div).addClass('hidden');
        if (bowler_index > 1) {
            $(remove_button_div_previous).removeClass('hidden');
        } else {
            $(remove_button_div_previous).addClass('hidden');
        }
        $(bowler_card).addClass('hidden');

        setBowlerCardShowing(bowler_index, "false");
        setBowlerRequired(bowler_index, false);
        // updateDoublesPartner(bowler_index, "remove");
        clearBowlerCard(bowler_index);

        // if (bowler_index <= 1) {
        //     $("#doubles-partner-selection-card").addClass("hidden");
        // }
        update_partners_selections(null);

        window.scrollTo({top: $(bowler_card_previous).offset().top, behavior: 'smooth'});
    });
}

function getExistingBowlerCount() {

    const bowler_num_str = $("#existing-bowler-count").val();

    if (bowler_num_str == "") {
        return 0;
    }
    else if (bowler_num_str == "0") {
        return 0;
    }
    else if (bowler_num_str == "1") {
        return 1;
    }
    else if (bowler_num_str == "2") {
        return 2;
    }
    else if (bowler_num_str == "3") {
        return 3;
    }
    // Should never happen
    else if (bowler_num_str == "4") {
        return 4;
    }
}

function match_doubles_partner_selections(event) {
    // console.log(">>>>>>>>>>>   match_doubles_partner_selections()");

    let selectorName = ""
    if (event) {
        selectorName = event.target.name;
        // console.log(" - selectorName = [" + selectorName + "]");
    }

    const previous_bowler_1 = $("#bowler_1_previous").val().trim();
    const previous_bowler_2 = $("#bowler_2_previous").val().trim();
    const previous_bowler_3 = $("#bowler_3_previous").val().trim();
    const previous_bowler_4 = $("#bowler_4_previous").val().trim();

    // console.log("previous_bowler_1 [" + previous_bowler_1 + "]");
    // console.log("previous_bowler_2 [" + previous_bowler_2 + "]");
    // console.log("previous_bowler_3 [" + previous_bowler_3 + "]");
    // console.log("previous_bowler_4 [" + previous_bowler_4 + "]");

    let select_bowler_1 = document.querySelector("#doubles_partner_position_1");
    let select_bowler_2 = document.querySelector("#doubles_partner_position_2");
    let select_bowler_3 = document.querySelector("#doubles_partner_position_3");
    let select_bowler_4 = document.querySelector("#doubles_partner_position_4");

    let selected_bowler_1 = select_bowler_1.options[select_bowler_1.selectedIndex].text;
    let selected_bowler_2 = select_bowler_2.options[select_bowler_2.selectedIndex].text;
    let selected_bowler_3 = select_bowler_3.options[select_bowler_3.selectedIndex].text;
    let selected_bowler_4 = select_bowler_4.options[select_bowler_4.selectedIndex].text;

    // console.log("selected_bowler_1 = [" + selected_bowler_1 + "]");
    // console.log("selected_bowler_2 = [" + selected_bowler_2 + "]");
    // console.log("selected_bowler_3 = [" + selected_bowler_3 + "]");
    // console.log("selected_bowler_4 = [" + selected_bowler_4 + "]");

    const bowler_name_1 = $("#bowlers_0_first_name").val().trim() + " " + $("#bowlers_0_last_name").val().trim();
    const bowler_name_2 = $("#bowlers_1_first_name").val().trim() + " " + $("#bowlers_1_last_name").val().trim();
    const bowler_name_3 = $("#bowlers_2_first_name").val().trim() + " " + $("#bowlers_2_last_name").val().trim();
    const bowler_name_4 = $("#bowlers_3_first_name").val().trim() + " " + $("#bowlers_3_last_name").val().trim();

    // console.log("bowler_name_1 = [" + bowler_name_1 + "]");
    // console.log("bowler_name_2 = [" + bowler_name_2 + "]");
    // console.log("bowler_name_3 = [" + bowler_name_3 + "]");
    // console.log("bowler_name_4 = [" + bowler_name_4 + "]");

    const bowler_label_1 = $("#doubles_partner_box_1 > div > label").html();
    const bowler_label_2 = $("#doubles_partner_box_2 > div > label").html();
    const bowler_label_3 = $("#doubles_partner_box_3 > div > label").html();
    const bowler_label_4 = $("#doubles_partner_box_4 > div > label").html();

    // console.log("bowler_label_1 = [" + bowler_label_1 + "]");
    // console.log("bowler_label_2 = [" + bowler_label_2 + "]");
    // console.log("bowler_label_3 = [" + bowler_label_3 + "]");
    // console.log("bowler_label_4 = [" + bowler_label_4 + "]");

    if (selectorName == "doubles_partner_position_1") {
        if (selected_bowler_1 == "-- Select Partner --" || selected_bowler_1 == "To be assigned later") {
            // Clear this bowler if they are selected anywhere else
            if (selected_bowler_2 == bowler_name_1 || selected_bowler_2 == 'Bowler 1') {
                event.preventDefault();
                select_bowler_2.selectedIndex = 0;
            }
            if (selected_bowler_3 == bowler_name_1 || selected_bowler_3 == 'Bowler 1') {
                event.preventDefault();
                select_bowler_3.selectedIndex = 0;
            }
            if (selected_bowler_4 == bowler_name_1 || selected_bowler_4 == 'Bowler 1') {
                event.preventDefault();
                select_bowler_4.selectedIndex = 0;
            }
        } else {
            // Find the selected bowler drop down and select this bowler as partner
            if (bowler_label_2 == selected_bowler_1) {
                // Find the index for this bowler and select it
                for (let i = 0; i < select_bowler_2.options.length; i++) {
                    if (select_bowler_2.options[i].text == bowler_label_1) {
                        event.preventDefault();
                        select_bowler_2.selectedIndex = i;
                        break;
                    }
                }
            } else if (bowler_label_3 == selected_bowler_1) {
                // Find the index for this bowler and select it
                for (let i = 0; i < select_bowler_3.options.length; i++) {
                    if (select_bowler_3.options[i].text == bowler_label_1) {
                        event.preventDefault();
                        select_bowler_3.selectedIndex = i;
                        break;
                    }
                }
            } else if (bowler_label_4 == selected_bowler_1) {
                // Find the index for this bowler and select it
                for (let i = 0; i < select_bowler_4.options.length; i++) {
                    if (select_bowler_4.options[i].text == bowler_label_1) {
                        event.preventDefault();
                        select_bowler_4.selectedIndex = i;
                        break;
                    }
                }
            }
        }

        if (selected_bowler_2 == selected_bowler_1) {
            if (selected_bowler_2 != "-- Select Partner --" && selected_bowler_2 != "To be assigned later") {
                event.preventDefault();
                select_bowler_2.selectedIndex = 0;
            }
        }
        if (selected_bowler_3 == selected_bowler_1) {
            if (selected_bowler_3 != "-- Select Partner --" && selected_bowler_3 != "To be assigned later") {
                event.preventDefault();
                select_bowler_3.selectedIndex = 0;
            }
        }
        if (selected_bowler_4 == selected_bowler_3) {
            if (selected_bowler_4 != "-- Select Partner --" && selected_bowler_4 != "To be assigned later") {
                event.preventDefault();
                select_bowler_4.selectedIndex = 0;
            }
        }

    }

    if (selectorName == "doubles_partner_position_2") {
        if (selected_bowler_2 == "-- Select Partner --" || selected_bowler_2 == "To be assigned later") {
            // Clear this bowler if they are selected anywhere else
            if (selected_bowler_1 == bowler_name_2 || selected_bowler_1 == 'Bowler 2') {
                event.preventDefault();
                select_bowler_1.selectedIndex = 0;
            }
            if (selected_bowler_3 == bowler_name_2 || selected_bowler_3 == 'Bowler 2') {
                event.preventDefault();
                select_bowler_3.selectedIndex = 0;
            }
            if (selected_bowler_4 == bowler_name_2 || selected_bowler_4 == 'Bowler 2') {
                event.preventDefault();
                select_bowler_4.selectedIndex = 0;
            }
        } else {
            // Find the selected bowler drop down and select this bowler as partner
            if (bowler_label_1 == selected_bowler_2) {
                // Find the index for this bowler and select it
                for (let i = 0; i < select_bowler_1.options.length; i++) {
                    if (select_bowler_1.options[i].text == bowler_label_2) {
                        event.preventDefault();
                        select_bowler_1.selectedIndex = i;
                        break;
                    }
                }
            } else if (bowler_label_3 == selected_bowler_2) {
                // Find the index for this bowler and select it
                for (let i = 0; i < select_bowler_3.options.length; i++) {
                    if (select_bowler_3.options[i].text == bowler_label_2) {
                        event.preventDefault();
                        select_bowler_3.selectedIndex = i;
                        break;
                    }
                }
            } else if (bowler_label_4 == selected_bowler_2) {
                // Find the index for this bowler and select it
                for (let i = 0; i < select_bowler_4.options.length; i++) {
                    if (select_bowler_4.options[i].text == bowler_label_2) {
                        event.preventDefault();
                        select_bowler_4.selectedIndex = i;
                        break;
                    }
                }
            }
        }

        if (selected_bowler_1 == selected_bowler_2) {
            if (selected_bowler_1 != "-- Select Partner --" && selected_bowler_1 != "To be assigned later") {
                event.preventDefault();
                select_bowler_1.selectedIndex = 0;
            }
        }
        if (selected_bowler_3 == selected_bowler_2) {
            if (selected_bowler_3 != "-- Select Partner --" && selected_bowler_3 != "To be assigned later") {
                event.preventDefault();
                select_bowler_3.selectedIndex = 0;
            }
        }
        if (selected_bowler_4 == selected_bowler_2) {
            if (selected_bowler_4 != "-- Select Partner --" && selected_bowler_4 != "To be assigned later") {
                event.preventDefault();
                select_bowler_4.selectedIndex = 0;
            }
        }

    }

    if (selectorName == "doubles_partner_position_3") {
        if (selected_bowler_3 == "-- Select Partner --" || selected_bowler_3 == "To be assigned later") {
            // Clear this bowler if they are selected anywhere else
            if (selected_bowler_1 == bowler_name_3 || selected_bowler_1 == 'Bowler 3') {
                event.preventDefault();
                select_bowler_1.selectedIndex = 0;
            }
            if (selected_bowler_2 == bowler_name_3 || selected_bowler_2 == 'Bowler 3') {
                event.preventDefault();
                select_bowler_2.selectedIndex = 0;
            }
            if (selected_bowler_4 == bowler_name_3 || selected_bowler_4 == 'Bowler 3') {
                event.preventDefault();
                select_bowler_4.selectedIndex = 0;
            }
        } else {
            // Find the selected bowler drop down and select this bowler as partner
            if (bowler_label_1 == selected_bowler_3) {
                // Find the index for this bowler and select it
                for (let i = 0; i < select_bowler_1.options.length; i++) {
                    if (select_bowler_1.options[i].text == bowler_label_3) {
                        event.preventDefault();
                        select_bowler_1.selectedIndex = i;
                        break;
                    }
                }
            } else if (bowler_label_2 == selected_bowler_3) {
                // Find the index for this bowler and select it
                for (let i = 0; i < select_bowler_2.options.length; i++) {
                    if (select_bowler_2.options[i].text == bowler_label_3) {
                        event.preventDefault();
                        select_bowler_2.selectedIndex = i;
                        break;
                    }
                }
            } else if (bowler_label_4 == selected_bowler_3) {
                // Find the index for this bowler and select it
                for (let i = 0; i < select_bowler_4.options.length; i++) {
                    if (select_bowler_4.options[i].text == bowler_label_3) {
                        event.preventDefault();
                        select_bowler_4.selectedIndex = i;
                        break;
                    }
                }
            }
        }

        if (selected_bowler_1 == selected_bowler_3) {
            if (selected_bowler_1 != "-- Select Partner --" && selected_bowler_1 != "To be assigned later") {
                event.preventDefault();
                select_bowler_1.selectedIndex = 0;
            }
        }
        if (selected_bowler_2 == selected_bowler_3) {
            if (selected_bowler_2 != "-- Select Partner --" && selected_bowler_2 != "To be assigned later") {
                event.preventDefault();
                select_bowler_2.selectedIndex = 0;
            }
        }
        if (selected_bowler_4 == selected_bowler_3) {
            if (selected_bowler_4 != "-- Select Partner --" && selected_bowler_4 != "To be assigned later") {
                event.preventDefault();
                select_bowler_4.selectedIndex = 0;
            }
        }
    }

    if (selectorName == "doubles_partner_position_4") {
        if (selected_bowler_4 == "-- Select Partner --" || selected_bowler_4 == "To be assigned later") {
            // Clear this bowler if they are selected anywhere else
            if (selected_bowler_1 == bowler_name_4 || selected_bowler_1 == 'Bowler 4') {
                event.preventDefault();
                select_bowler_1.selectedIndex = 0;
            }
            if (selected_bowler_2 == bowler_name_4 || selected_bowler_2 == 'Bowler 4') {
                event.preventDefault();
                select_bowler_2.selectedIndex = 0;
            }
            if (selected_bowler_3 == bowler_name_4 || selected_bowler_3 == 'Bowler 4') {
                event.preventDefault();
                select_bowler_3.selectedIndex = 0;
            }
        } else {
            // Find the selected bowler drop down and select this bowler as partner
            if (bowler_label_1 == selected_bowler_4) {
                // Find the index for this bowler and select it
                for (let i = 0; i < select_bowler_1.options.length; i++) {
                    if (select_bowler_1.options[i].text == bowler_label_4) {
                        event.preventDefault();
                        select_bowler_1.selectedIndex = i;
                        break;
                    }
                }
            } else if (bowler_label_2 == selected_bowler_4) {
                // Find the index for this bowler and select it
                for (let i = 0; i < select_bowler_2.options.length; i++) {
                    if (select_bowler_2.options[i].text == bowler_label_4) {
                        event.preventDefault();
                        select_bowler_2.selectedIndex = i;
                        break;
                    }
                }
            } else if (bowler_label_3 == selected_bowler_4) {
                // Find the index for this bowler and select it
                for (let i = 0; i < select_bowler_3.options.length; i++) {
                    if (select_bowler_3.options[i].text == bowler_label_4) {
                        event.preventDefault();
                        select_bowler_3.selectedIndex = i;
                        break;
                    }
                }
            }
        }

        if (selected_bowler_1 == selected_bowler_4) {
            if (selected_bowler_1 != "-- Select Partner --" && selected_bowler_1 != "To be assigned later") {
                event.preventDefault();
                select_bowler_1.selectedIndex = 0;
            }
        }
        if (selected_bowler_2 == selected_bowler_4) {
            if (selected_bowler_1 != "-- Select Partner --" && selected_bowler_1 != "To be assigned later") {
                event.preventDefault();
                select_bowler_2.selectedIndex = 0;
            }
        }
        if (selected_bowler_3 == selected_bowler_4) {
            if (selected_bowler_1 != "-- Select Partner --" && selected_bowler_1 != "To be assigned later") {
                event.preventDefault();
                select_bowler_3.selectedIndex = 0;
            }
        }

    }
}

function update_partners_selections(changeEvent) {
    // console.log(">>>>>>>>>>>   update_partners_selections()");

    if (changeEvent) {
        // console.log("changeEvent = " + changeEvent);
        // console.log("changeEvent.target = " + changeEvent.target);
        // console.log("changeEvent.target = " + changeEvent.target.name);
    }

    const previous_bowler_1 = $("#bowler_1_previous").val().trim();
    const previous_bowler_2 = $("#bowler_2_previous").val().trim();
    const previous_bowler_3 = $("#bowler_3_previous").val().trim();
    const previous_bowler_4 = $("#bowler_4_previous").val().trim();

    // console.log("previous_bowler_1 [" + previous_bowler_1 + "]");
    // console.log("previous_bowler_2 [" + previous_bowler_2 + "]");
    // console.log("previous_bowler_3 [" + previous_bowler_3 + "]");
    // console.log("previous_bowler_4 [" + previous_bowler_4 + "]");

    const bowlerCount = numberOfBowlers();

    const bowler_name_1 = $("#bowlers_0_first_name").val().trim() + " " + $("#bowlers_0_last_name").val().trim();
    const bowler_name_2 = $("#bowlers_1_first_name").val().trim() + " " + $("#bowlers_1_last_name").val().trim();
    const bowler_name_3 = $("#bowlers_2_first_name").val().trim() + " " + $("#bowlers_2_last_name").val().trim();
    const bowler_name_4 = $("#bowlers_3_first_name").val().trim() + " " + $("#bowlers_3_last_name").val().trim();

    // function initializeDoublesPartner(bowlerIndex) {
    //     const doubles_partner_note = "#doubles-partner-note-" + bowlerIndex;
    //     const label_for_doubles_selector = "label[for=bowlers_" + bowlerIndex + "_doubles_partner_position]";
    //     const doubles_selector = "#bowlers_" + bowlerIndex + "_doubles_partner_position";
    //     const doubles_selector_first_option = doubles_selector + " option:eq(0)";
    //     $(doubles_partner_note).removeClass("hidden");
    //     $(label_for_doubles_selector).addClass("disabled-label");
    //     $(doubles_selector_first_option).text("");
    //     $(doubles_selector).prop("disabled", true);
    // }
    // function doublesPartnerSelectionChange(sb) {
    //     const bowlerIndex = $(sb).attr("id").replace(/bowlers_/, "").replace(/_doubles_partner_position/, "");
    //     const doublesVal = $(sb).val();
    //     console.log("doublesPartnerSelectionChange - bowlerIndex: " + bowlerIndex + ", doublesVal: " + doublesVal);
    //     console.log("Bowler " + (parseInt(bowlerIndex) + 1) + " has selected " + doublesVal);
    // }

    // const doubles_selector_first_option = doubles_selector + " option:eq(0)";

    removeSelectOptions("#doubles_partner_position_1");
    removeSelectOptions("#doubles_partner_position_2");
    removeSelectOptions("#doubles_partner_position_3");
    removeSelectOptions("#doubles_partner_position_4");

    if (bowler_name_1.length > 1) {
        $("#doubles_partner_box_1 > div > label").html(bowler_name_1);
    }
    if (bowler_name_2.length > 1) {
        $("#doubles_partner_box_2 > div > label").html(bowler_name_2);
    }
    if (bowler_name_3.length > 1) {
        $("#doubles_partner_box_3 > div > label").html(bowler_name_3);
    }
    if (bowler_name_4.length > 1) {
        $("#doubles_partner_box_4 > div > label").html(bowler_name_4);
    }

    let select = document.querySelector("#doubles_partner_position_1");
    if (bowler_name_2.length > 1) {
        addOption(select, bowler_name_2, 'Bowler 2');
    } else if (bowlerCount > 1) {
        addOption(select, 'Bowler 2', 'Bowler 2');
    }
    if (bowler_name_3.length > 1) {
        addOption(select, bowler_name_3, 'Bowler 3');
    } else if (bowlerCount > 2) {
        addOption(select, 'Bowler 3', 'Bowler 3');
    }
    if (bowler_name_4.length > 1) {
        addOption(select, bowler_name_4, 'Bowler 4');
    } else if (bowlerCount > 3) {
        addOption(select, 'Bowler 4', 'Bowler 4');
    }
    addPreviousBowlers(select, previous_bowler_1, previous_bowler_2, previous_bowler_3, previous_bowler_4);
    addOption(select, 'To be assigned later', 'Bowler-To-Be-Assigned');

    select = document.querySelector("#doubles_partner_position_2");
    if (bowler_name_1.length > 1) {
        addOption(select, bowler_name_1, 'Bowler 1');
    } else if (bowlerCount > 1) {
        addOption(select, 'Bowler 1', 'Bowler 1');
    }
    if (bowler_name_3.length > 1) {
        addOption(select, bowler_name_3, 'Bowler 3');
    } else if (bowlerCount > 2) {
        addOption(select, 'Bowler 3', 'Bowler 3');
    }
    if (bowler_name_4.length > 1) {
        addOption(select, bowler_name_4, 'Bowler 4');
    } else if (bowlerCount > 3) {
        addOption(select, 'Bowler 4', 'Bowler 4');
    }
    addPreviousBowlers(select, previous_bowler_1, previous_bowler_2, previous_bowler_3, previous_bowler_4);
    addOption(select, 'To be assigned later', 'Bowler-To-Be-Assigned');

    select = document.querySelector("#doubles_partner_position_3");
    if (bowler_name_1.length > 1) {
        addOption(select, bowler_name_1, 'Bowler 1');
    } else if (bowlerCount > 1) {
        addOption(select, 'Bowler 1', 'Bowler 1');
    }
    if (bowler_name_2.length > 1) {
        addOption(select, bowler_name_2, 'Bowler 2');
    } else if (bowlerCount > 2) {
        addOption(select, 'Bowler 2', 'Bowler 2');
    }
    if (bowler_name_4.length > 1) {
        addOption(select, bowler_name_4, 'Bowler 4');
    } else if (bowlerCount > 3) {
        addOption(select, 'Bowler 4', 'Bowler 4');
    }
    addPreviousBowlers(select, previous_bowler_1, previous_bowler_2, previous_bowler_3, previous_bowler_4);
    addOption(select, 'To be assigned later', 'Bowler-To-Be-Assigned');

    select = document.querySelector("#doubles_partner_position_4");
    if (bowler_name_1.length > 1) {
        addOption(select, bowler_name_1, 'Bowler 1');
    } else if (bowlerCount > 1) {
        addOption(select, 'Bowler 1', 'Bowler 1');
    }
    if (bowler_name_2.length > 1) {
        addOption(select, bowler_name_2, 'Bowler 2');
    } else if (bowlerCount > 2) {
        addOption(select, 'Bowler 2', 'Bowler 2');
    }
    if (bowler_name_3.length > 1) {
        addOption(select, bowler_name_3, 'Bowler 3');
    } else if (bowlerCount > 3) {
        addOption(select, 'Bowler 3', 'Bowler 3');
    }
    addPreviousBowlers(select, previous_bowler_1, previous_bowler_2, previous_bowler_3, previous_bowler_4);
    addOption(select, 'To be assigned later', 'Bowler-To-Be-Assigned');

    showHideDoubles(bowlerCount);
}

function showHideDoubles(bowlerCount) {
    // console.log(">>>>>>>>>>>   showHideDoubles()");

    if (bowlerCount == 1) {
        // console.log("bowlerCount is 1");
        $("#doubles_partner_box_1").removeClass("hidden");
        $("#doubles_partner_box_2").addClass("hidden");
        $("#doubles_partner_box_2 > div > label").html("Bowler 2");
        $("#doubles_partner_box_3").addClass("hidden");
        $("#doubles_partner_box_3 > div > label").html("Bowler 3");
        $("#doubles_partner_box_4").addClass("hidden");
        $("#doubles_partner_box_4 > div > label").html("Bowler 4");
    } else if (bowlerCount == 2) {
        // console.log("bowlerCount is 2");
        $("#doubles_partner_box_1").removeClass("hidden");
        $("#doubles_partner_box_2").removeClass("hidden");
        $("#doubles_partner_box_3").addClass("hidden");
        $("#doubles_partner_box_3 > div > label").html("Bowler 3");
        $("#doubles_partner_box_4").addClass("hidden");
        $("#doubles_partner_box_4 > div > label").html("Bowler 4");
    } else if (bowlerCount == 3) {
        // console.log("bowlerCount is 3");
        $("#doubles_partner_box_1").removeClass("hidden");
        $("#doubles_partner_box_2").removeClass("hidden");
        $("#doubles_partner_box_3").removeClass("hidden");
        $("#doubles_partner_box_4").addClass("hidden");
        $("#doubles_partner_box_4 > div > label").html("Bowler 4");
    } else if (bowlerCount == 4) {
        // console.log("bowlerCount is 4");
        $("#doubles_partner_box_1").removeClass("hidden");
        $("#doubles_partner_box_2").removeClass("hidden");
        $("#doubles_partner_box_3").removeClass("hidden");
        $("#doubles_partner_box_4").removeClass("hidden");
    }
}

function addPreviousBowlers(select, previous_bowler_1, previous_bowler_2, previous_bowler_3, previous_bowler_4) {
    // console.log(">>>>>>>>>>>   addPreviousBowlers()");

    if (previous_bowler_1.length > 0) {
        addOption(select, previous_bowler_1, previous_bowler_1);
    }
    if (previous_bowler_2.length > 0) {
        addOption(select, previous_bowler_2, previous_bowler_2);
    }
    if (previous_bowler_3.length > 0) {
        addOption(select, previous_bowler_3, previous_bowler_3);
    }
    if (previous_bowler_4.length > 0) {
        addOption(select, previous_bowler_4, previous_bowler_4);
    }
}

function addOption(select, text, value) {
    // console.log(">>>>>>>>>>>   addOption()");

    const newOption = document.createElement('option');
    const optionText = document.createTextNode(text);
    newOption.appendChild(optionText);
    newOption.setAttribute('value', value);
    select.appendChild(newOption);
    // console.log("adding option [" + optionText.textContent + "]")
}

function removeSelectOptions(selectStr) {
    // console.log(">>>>>>>>>>>   removeSelectOptions()");
    // console.log("selectStr = " + selectStr);

    let select = document.querySelector(selectStr);
    let i, L = select.options.length - 1;
    // console.log("select.options.length = " + select.options.length);
    for (i = L; i >= 1; i--) {
        // console.log("removing " + i + " [" + select.options[i].text + "]");
        select.remove(i);
    }
}

function initializeDoublesPartner(bowlerIndex) {
    // console.log(">>>>>>>>>>>   initializeDoublesPartner()");

    const doubles_partner_note = "#doubles-partner-note-" + bowlerIndex;
    const label_for_doubles_selector = "label[for=bowlers_" + bowlerIndex + "_doubles_partner_position]";
    const doubles_selector = "#bowlers_" + bowlerIndex + "_doubles_partner_position";
    const doubles_selector_first_option = doubles_selector + " option:eq(0)";

    $(doubles_partner_note).removeClass('hidden');
    $(label_for_doubles_selector).addClass('disabled-label');
    $(doubles_selector_first_option).text('');
    $(doubles_selector).prop('disabled', true);
}

function doublesPartnerSelectionChange(sb) {
    // console.log(">>>>>>>>>>>   doublesPartnerSelectionChange()");

    const bowlerIndex = $(sb).attr('id').replace(/bowlers_/, '').replace(/_doubles_partner_position/, '');
    const doublesVal = $(sb).val();

    // console.log("doublesPartnerSelectionChange - bowlerIndex: " + bowlerIndex + ", doublesVal: " + doublesVal);
    // console.log("Bowler " + (parseInt(bowlerIndex) + 1) + " has selected " + doublesVal);

}

function removeRedErrorLabel(idx, field_name) {
    const label_for_field = "label[for=bowlers_" + idx + "_" + field_name + "]";
    $(label_for_field).removeClass('red-error-label');
}

function clearBowlerCard(bowlerIndex) {
    // console.log("clearBowlerCard for bowlerIndex: " + bowlerIndex);

    $("#bowlers_" + bowlerIndex + "_first_name").val("");
    removeRedErrorLabel(bowlerIndex, "first_name");
    $("#bowlers_" + bowlerIndex + "_last_name").val("");
    removeRedErrorLabel(bowlerIndex, "last_name");

    const doubles_selector = "#bowlers_" + bowlerIndex + "_doubles_partner_position";
    const doubles_selector_first_option = doubles_selector + " option:eq(0)";
    $(doubles_selector_first_option).prop('selected', true);

    const captain_selector = "#bowlers_" + bowlerIndex + "_captain";
    const captain_selector_first_option = captain_selector + " option:eq(0)";
    $(captain_selector_first_option).prop('selected', true);
    removeRedErrorLabel(bowlerIndex, "captain");

    $("#bowlers_" + bowlerIndex + "_address_line_1").val("");
    removeRedErrorLabel(bowlerIndex, "address_line_1");
    $("#bowlers_" + bowlerIndex + "_address_line_2").val("");
    removeRedErrorLabel(bowlerIndex, "address_line_2");
    $("#bowlers_" + bowlerIndex + "_city").val("");
    removeRedErrorLabel(bowlerIndex, "city");
    $("#bowlers_" + bowlerIndex + "_state").val("");
    removeRedErrorLabel(bowlerIndex, "state");
    $("#bowlers_" + bowlerIndex + "_usbc_id").val("");
    removeRedErrorLabel(bowlerIndex, "usbc_id");
    $("#bowlers_" + bowlerIndex + "_zip").val("");
    removeRedErrorLabel(bowlerIndex, "zip");
    $("#bowlers_" + bowlerIndex + "_phone").val("");
    removeRedErrorLabel(bowlerIndex, "phone");
    $("#bowlers_" + bowlerIndex + "_email").val("");
    removeRedErrorLabel(bowlerIndex, "email");

    // document.getElementById("bowlers_" + bowlerIndex + "_igbo_league_member").checked = false;

    const birth_month_selector = "#bowlers_" + bowlerIndex + "_birth_month";
    const birth_month_selector_first_option = birth_month_selector + " option:eq(0)";
    $(birth_month_selector_first_option).prop('selected', true);
    removeRedErrorLabel(bowlerIndex, "birth_month");

    const birth_day_selector = "#bowlers_" + bowlerIndex + "_birth_day";
    const birth_day_selector_first_option = birth_day_selector + " option:eq(0)";
    $(birth_day_selector_first_option).prop('selected', true);
    removeRedErrorLabel(bowlerIndex, "birth_day");

    document.getElementById("bowlers_" + bowlerIndex + "_scratch_masters").checked = false;
    document.getElementById("bowlers_" + bowlerIndex + "_optional_scratch").checked = false;

    const scratch_division_selector = "#bowlers_" + bowlerIndex + "_scratch_division";
    const scratch_division_selector_first_option = scratch_division_selector + " option:eq(0)";
    $(scratch_division_selector_first_option).prop('selected', true);
    const label_for_count_selector = "label[for=bowlers_" + bowlerIndex + "_scratch_division]";
    const division_selector = "#bowlers_" + bowlerIndex + "_scratch_division";
    const division_selector_first_option = division_selector + " option:eq(0)";
    $(label_for_count_selector).addClass('disabled-label');
    $(division_selector).prop('disabled', true);
    $(division_selector_first_option).text('');
    $(division_selector_first_option).prop('selected', true);

    const free_entry_selector = "#bowlers_" + bowlerIndex + "_free_entry";
    const free_entry_selector_first_option = free_entry_selector + " option:eq(0)";
    $(free_entry_selector_first_option).prop('selected', true);
    const free_entry_code_selector = 'input[type="text"][id$="bowlers_' + bowlerIndex + '_free_entry_code"]';
    $(free_entry_code_selector).val('');
    const free_entry_code_div = "free_entry_code_div_" + bowlerIndex;
    $("#"+free_entry_code_div).addClass('hidden');

    const diet_textarea_id = "diet-textarea-bowler-" + bowlerIndex;
    const diet_requirement_div = "diet_requirement_div_" + bowlerIndex;
    $("#"+diet_requirement_div).addClass('hidden');
    if (document.getElementById(diet_textarea_id)) {
        document.getElementById(diet_textarea_id).innerText = "";
    }
    $("#"+diet_textarea_id).val('');
    const banquet_selector = "#bowlers_" + bowlerIndex + "_banquet";
    const banquet_selector_first_option = banquet_selector + " option:eq(0)";
    $(banquet_selector_first_option).prop('selected', true);
    const label_for_banquet_selector = "label[for=bowlers_" + bowlerIndex + "_banquet]";
    $(label_for_banquet_selector).removeClass('red-error-label');

    const diet_restriction_div = "diet_restriction_div_" + bowlerIndex;
    $("#"+diet_restriction_div).addClass('hidden');
    const diet_restriction_selector = "#bowlers_" + bowlerIndex + "_diet_restriction";
    const diet_restriction_first_option = diet_restriction_selector + " option:eq(0)";
    $(diet_restriction_first_option).prop('selected', true);

    const sectionSelector = "#non-participant-" + bowlerIndex;
    $(sectionSelector).addClass("hidden");
    let checkboxSelector = "bowlers_" + bowlerIndex + "_non_participant";
    if (document.getElementById(checkboxSelector)) {
        document.getElementById(checkboxSelector).checked = false;
    }
    clearNonParticipantFields(bowlerIndex, "first_name");
    clearNonParticipantFields(bowlerIndex, "last_name");
    clearNonParticipantFields(bowlerIndex, "city");
    clearNonParticipantFields(bowlerIndex, "state");

    checkboxSelector = "bowlers_" + bowlerIndex + "_igbo_rep";
    if (document.getElementById(checkboxSelector)) {
        document.getElementById(checkboxSelector).checked = false;
    }
    checkboxSelector = "bowlers_" + bowlerIndex + "_fellowship_award";
    if (document.getElementById(checkboxSelector)) {
        document.getElementById(checkboxSelector).checked = false;
    }
    checkboxSelector = "bowlers_" + bowlerIndex + "_tom_hack_award";
    if (document.getElementById(checkboxSelector)) {
        document.getElementById(checkboxSelector).checked = false;
    }

    const gender_selector = "#bowlers_" + bowlerIndex + "_gender";
    const gender_selector_first_option = gender_selector + " option:eq(0)";
    $(gender_selector_first_option).prop('selected', true);
    removeRedErrorLabel(bowlerIndex, "gender");

    const country_code_selector = "#bowlers_" + bowlerIndex + "_country";
    $(country_code_selector).val('');
    removeRedErrorLabel(bowlerIndex, "country");

    const igbo_id_selector = "#bowlers_" + bowlerIndex + "_igbo_id";
    $(igbo_id_selector).val('');
    removeRedErrorLabel(bowlerIndex, "igbo_id");

    checkboxSelector = "bowlers_" + bowlerIndex + "_associate_igbo_league_member";
    if (document.getElementById(checkboxSelector)) {
        document.getElementById(checkboxSelector).checked = false;
    }

    const bowlerLeagueCountSelector = "#bowlers_" + bowlerIndex + "_league_count";
    const selectedLeagueCount = $(bowlerLeagueCountSelector).text();
    const league_count_selector_first_option = bowlerLeagueCountSelector + " option:eq(0)";
    $(league_count_selector_first_option).prop('selected', true);
    removeRedErrorLabel(bowlerIndex, "league_count");
    let max_league_count = 5;
    if (selectedLeagueCount != '-- Make Selection --' && selectedLeagueCount != "None") {
        for (let i = 1; i <= max_league_count; i++) {
            let league_section_selector = "#league-id-" + i + "-b" + bowlerIndex;
            $(league_section_selector).addClass("hidden");
            hideLeagueFields(i, bowlerIndex);
        }
    }

    const rainbow_pack_selector = "#bowlers_" + bowlerIndex + "_rainbow_pack_type";
    const rainbow_pack_selector_first_option = rainbow_pack_selector + " option:eq(0)";
    $(rainbow_pack_selector_first_option).prop('selected', true);
}

// function updateDoublesPartner(bowlerIndex, action) {
//     // console.log("updateDoublesPartner - bowlerIndex: " + bowlerIndex + ", action: " + action);
//
//     if (bowlerIndex === 0 && action === "add") {
//         const doubles_partner_note = "#doubles-partner-note-" + bowlerIndex;
//         const label_for_doubles_selector = "label[for=bowlers_" + bowlerIndex + "_doubles_partner_position]";
//         const doubles_selector = "#bowlers_" + bowlerIndex + "_doubles_partner_position";
//         const doubles_selector_first_option = doubles_selector + " option:eq(0)";
//
//         $(doubles_partner_note).addClass('hidden');
//         $(label_for_doubles_selector).removeClass('disabled-label');
//         $(doubles_selector_first_option).text('-- Select Doubles Partner --');
//         $(doubles_selector).prop('disabled', false);
//     }
//
//     if (bowlerIndex === 1 && action === "remove") {
//         const doubles_partner_note = "#doubles-partner-note-" + (bowlerIndex - 1);
//         const label_for_doubles_selector = "label[for=bowlers_" + (bowlerIndex - 1) + "_doubles_partner_position]";
//         const doubles_selector = "#bowlers_" + (bowlerIndex - 1) + "_doubles_partner_position";
//         const doubles_selector_first_option = doubles_selector + " option:eq(0)";
//
//         $(doubles_partner_note).removeClass('hidden');
//         $(label_for_doubles_selector).addClass('disabled-label');
//         $(doubles_selector).prop('disabled', true);
//         $(doubles_selector_first_option).text('');
//         $(doubles_selector_first_option).prop('selected', true);
//     }
// }

function runSiteAs() {
    // console.log(">>>>>>>>>>>   runSiteAs()");

    return $('#run-site-as').val();
}

function igboSite() {
    // console.log(">>>>>>>>>>>   igboSite()");

    return runSiteAs() === 'igbo';
}

function espressoSite() {
    // console.log(">>>>>>>>>>>   espressoSite()");

    return runSiteAs() === 'espresso';
}

function getTeams() {
    const team_json = JSON.parse($('#at-teams').val());
    return team_json;
}

function setBowlerCardShowing(bowlerIndex, myVal) {
    // console.log(">>>>>>>>>>>   setBowlerCardShowing()");

    const cardSelector = "#bowler-card-" + bowlerIndex + "-showing";
    $(cardSelector).val(myVal);
}

function setBowlerRequired(bowl_num, required) {
    // console.log(">>>>>>>>>>>   setBowlerRequired()");

    setElementRequired("bowlers_" + bowl_num + "_first_name", required);
    setElementRequired("bowlers_" + bowl_num + "_last_name", required);
    setElementRequired("bowlers_" + bowl_num + "_captain", required);

    if (igboSite()) {
        setElementRequired("bowlers_" + bowl_num + "_gender", required);
    }

    setElementRequired("bowlers_" + bowl_num + "_address_line_1", required);
    setElementRequired("bowlers_" + bowl_num + "_city", required);
    setElementRequired("bowlers_" + bowl_num + "_state", required);
    setElementRequired("bowlers_" + bowl_num + "_zip", required);

    setElementRequired("bowlers_" + bowl_num + "_usbc_id", required);
    setElementRequired("bowlers_" + bowl_num + "_birth_month", required);
    setElementRequired("bowlers_" + bowl_num + "_birth_day", required);

}

function setElementRequired(selector, required) {
    // console.log(">>>>>>>>>>>   setElementRequired()");

    let elementById = document.getElementById(selector);

    if (!elementById) {
        // console.log("ERROR: setElementRequired cannot find element with selector: " + selector);
        return
    }

    if (required) {
        elementById.setAttribute('required', 'true');
    } else {
        elementById.removeAttribute('required');
    }
}

function init() {
    // console.log(">>>>>>>>>>>   init()");

    $("[rel='tooltip']").tooltip();

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })
}

function initBowlersForm() {
    // console.log(">>>>>>>>>>>   initBowlersForm()");

    $('#add_bowler_entry_form_div_0').removeClass('invisible');
    setBowlerRequired(0, true);
    toggleTeamName();

    if (espressoSite()) {
        $("#shift-preference").addClass("hidden");
    }

    toggleScratch(0, null);
    toggleScratch(1, null);
    toggleScratch(2, null);
    toggleScratch(3, null);

    initializeDoublesPartner(0);
    initializeDoublesPartner(1);
    initializeDoublesPartner(2);
    initializeDoublesPartner(3);

    update_partners_selections(null);

    $("#new_team_name").on('change paste', function () {
        checkTeamExist4Bowlers(this);
    });

    $('input[type="text"][id^="team_action_name"]').each(function () {
        $(this).change(function () {
            checkTeamExist(this);
        });
    });

    $('input[type="radio"][id^="team_action"]').each(function () {
        $(this).change(function () {
            toggleTeamName();
        });
    });

    const teamSelector = document.getElementById("team_to_join");
    if (teamSelector) {
        teamSelector.addEventListener("change", function () {
            teamSelectionChange();
        });
    }

    $('select[id$="_league_count"]').each(function () {
        $(this).change(function () {
            toggleBowlerLeagues(this);
        });
    });

    $('select[id$="_banquet"]').each(function () {
        $(this).change(function () {
            toggleMeal(this);
        });
    });

    $('select[id$="_diet_restriction"]').each(function () {
        $(this).change(function () {
            toggleMealRestriction(this);
        });
    });

    $('input[type="checkbox"][id$="_scratch_masters"]').each(function () {
        $(this).change(function () {
            toggleScratch(null, this);
        });
    });

    $('input[type="checkbox"][id$="_optional_scratch"]').each(function () {
        $(this).change(function () {
            toggleScratch(null, this);
        });
    });

    $('select[id$="_free_entry"]').each(function () {
        $(this).change(function () {
            toggleFreeEntry(this);
        });
    });

    $('input[type="checkbox"][id$="_non_participant"]').each(function () {
        $(this).change(function () {
            toggleNonParticipant(this);
        });
    });

    $('select[id$="_doubles_partner_position"]').each(function () {
        $(this).change(function () {
            doublesPartnerSelectionChange(this);
        });
    });

    // $('#bowlers_3_first_name').change(function () {
    //     update_partners_selections(this);
    // })

    // window.scrollTo({top: $("#bowler-entry-form").offset().top, behavior: 'smooth'});
}

function teamSelectionChange(event) {
    // console.log(">>>>>>>>>>>   teamSelectionChange()");

    const teamSelector = document.getElementById("team_to_join");
    const value = teamSelector.value;
    const selectedTeam = teamSelector.options[teamSelector.selectedIndex].text;

    // console.log("value = " + value);
    // console.log("Team selected = " + selectedTeam);

    // if (selectedTeam != "" && selectedTeam != "-- Select Team --") {
    //     // const teamBowlers = lookupExistingTeam(selectedTeam);
    //     // console.log("teamBowlers = " + teamBowlers);
    // }

    update_partners_selections(event);
}

function toggleTeamName() {
    // console.log(">>>>>>>>>>>   toggleTeamName()");

    // Create a new team
    const newTeamChecked = $('input[type="radio"][id$="team_action_new"]').is(':checked');
    $('#new_team_name').prop('disabled', !(newTeamChecked));
    if (newTeamChecked) {
        $('label[for=new_team_name]').removeClass('disabled-label');
        $('#team_to_join option:eq(0)').prop('selected', true);
        $("#shift-preference").removeClass("hidden");
        shiftPreferenceRequired(true);
        $("#shift-preference-already_set").addClass("hidden");
        $("#missing-shift-preferences-error-id").addClass("hidden");
    } else {
        $('label[for=new_team_name]').addClass('disabled-label');
        $("#team-already-exists-error-id").addClass("hidden");
        $("#shift-preference").addClass("hidden");
    }

    // const noTeamsYet =
    const noTeamsYet = document.querySelectorAll('#team_to_join').length < 1;
    // console.log("noTeamsYet = " + noTeamsYet);

    // Join an existing team
    if (noTeamsYet) {
        // Save this code, it shows how to completely disable a Selection Drop Down Options List
        $('label[for=team_to_join]').addClass('disabled-label');
        $('input[type="radio"][id$="team_action_join"]').attr('disabled', true);
        $('#team-option-select-id option:eq(0)').prop('selected', true).text("No teams exist yet");
        $('#team-option-select-id').attr('disabled', true);
        $('#team-option-select-id').addClass("disabled");
        $('#team-option-select-id').addClass("disabled-label");

    } else {
        $('input[type="radio"][id$="team_action_join"]').attr('disabled', false);
        $('#team_to_join').attr('disabled', false);
        const joinTeamChecked = $('input[type="radio"][id$="team_action_join"]').is(':checked');
        $('#team_to_join').prop('disabled', !(joinTeamChecked));
        if (joinTeamChecked) {
            $('label[for=team_to_join]').removeClass('disabled-label');
            $('input[type="text"][id$="new_team_name"]').val('');
            $('#team_to_join option:eq(0)').text("-- Select Team --");
            $('#team_to_join option:eq(0)').addClass("smaller-button");
            shiftPreferenceRequired(false);
            $("#shift-preference").addClass("hidden");
            $("#shift-preference-already_set").removeClass("hidden");
        } else {
            $('label[for=team_to_join]').addClass('disabled-label');
            $('#team_to_join option:eq(0)').prop('selected', true).text("");
        }
    }

    // Director pick a team
    const pickTeamChecked = $('input[type="radio"][id$="team_action_pick"]').is(':checked');
    if (pickTeamChecked) {
        $('#team_to_join option:eq(0)').prop('selected', true)
        $('input[type="text"][id$="new_team_name"]').val('');

        $("#shift-preference").removeClass("hidden");
        shiftPreferenceRequired(true);
        $("#shift-preference-already_set").addClass("hidden");
    }

    $("#bowler_1_previous").val("");
    $("#bowler_2_previous").val("");
    $("#bowler_3_previous").val("");
    $("#bowler_4_previous").val("");
    update_partners_selections(null);
}

function toggleBowlerLeagues(sb) {
    const id = $(sb).attr('id').replace(/bowlers_/, '').replace(/_league_count/, '');

    const bowlerLeagueCountSelector = "#bowlers_" + id + "_league_count option:selected";
    const selectedLeagueCount = $(bowlerLeagueCountSelector).text();

    let max_league_count = 5;

    if (selectedLeagueCount == '-- Make Selection --' || selectedLeagueCount == "None") {
        for (let i = 1; i <= max_league_count; i++) {
            let league_section_selector = "#league-id-" + i + "-b" + id;
            $(league_section_selector).addClass("hidden");
            hideLeagueFields(i, id);
        }
    } else {
        for (let i = 1; i <= max_league_count; i++) {
            let league_section_selector = "#league-id-" + i + "-b" + id;

            if (i <= selectedLeagueCount) {
                $(league_section_selector).removeClass("hidden");
            } else {
                $(league_section_selector).addClass("hidden");
                hideLeagueFields(i, id);
            }
        }
    }
}

function hideLeagueFields(i, id) {
    let league_name_selector = "#league_name_" + i + "_b" + id;
    $(league_name_selector).val("");
    const label_for_name_selector = "label[for='league_name_" + i + "_b" + id + "']";
    $(label_for_name_selector).removeClass('red-error-label');

    let league_center_selector = "#league_center_" + i + "_b" + id;
    $(league_center_selector).val("");
    const label_for_center_selector = "label[for='league_center_" + i + "_b" + id + "']";
    $(label_for_center_selector).removeClass('red-error-label');

    let league_secretary_selector = "#league_secretary_" + i + "_b" + id;
    $(league_secretary_selector).val("");
    const label_for_secretary_selector = "label[for='league_secretary_" + i + "_b" + id + "']";
    $(label_for_secretary_selector).removeClass('red-error-label');

    let league_contact_selector = "#league_contact_" + i + "_b" + id;
    $(league_contact_selector).val("");
    const label_for_contact_selector = "label[for='league_contact_" + i + "_b" + id + "']";
    $(label_for_contact_selector).removeClass('red-error-label');

    // let league_igbo_selector = "#league_igbo_" + i + "_b" + id;
    // $(league_igbo_selector).val("");
    // const label_for_contact_selector = "label[for='league_contact_" + i + "_b" + id + "']";
    // $(label_for_contact_selector).removeClass('red-error-label');

    document.getElementById("league_igbo_" + i + "_b" + id).checked = false;

}

function toggleMeal(ms) {
    const id = $(ms).attr('id').replace(/bowlers_/, '').replace(/_banquet/, '');
    const meal_val = $(ms).val();

    const diet_label_selector = "label[for=diet-textarea-bowler-" + id + "]";
    // const diet_textarea_selector = "textarea[for=bowlers_" + id + "_diet]";

    const diet_restriction_div = "diet_restriction_div_" + id;
    const diet_requirement_div = "diet_requirement_div_" + id;
    const diet_textarea_id = "diet-textarea-bowler-" + id;

    if (meal_val == '' || meal_val == 'No') {
        $("#"+diet_restriction_div).addClass('hidden');

        const diet_restriction_selector = "#bowlers_" + id + "_diet_restriction";
        const diet_restriction_first_option = diet_restriction_selector + " option:eq(0)";
        $(diet_restriction_first_option).prop('selected', true);

        $("#"+diet_requirement_div).addClass('hidden');
        $("#"+diet_textarea_id).val('');

        // TODO: hide the radio buttons

        // TODO: unselect all radio buttons
        // TODO: hide the "diet requirements" box
        // $("#"+diet_label_selector).addClass('disabled-label');
        // // $(diet_textarea_selector).prop('disabled', true);
        // document.getElementById(diet_textarea_id).innerText = "";
        // $("#"+diet_textarea_id).val('');
        // document.getElementById(diet_textarea_id).readOnly = true;
    } else {
        $("#"+diet_restriction_div).removeClass('hidden');

        // $("#"+diet_requirement_div).removeClass('hidden');

        // TODO: which radio button is selected, if none that's OK since we might be unhiding it
        // TODO: Show the radio buttons



        // TODO: If "Other" radio button selected then show "diet requirements" box
        // $("#"+diet_label_selector).removeClass('disabled-label');
        // // $(diet_textarea_selector).prop('disabled', false);
        // document.getElementById(diet_textarea_id).readOnly = false;

        // TODO: If "other" radio button not selected then hide the "diet requirements" box
    }
}

function toggleMealRestriction(ms) {
    const id = $(ms).attr('id').replace(/bowlers_/, '').replace(/_diet_restriction/, '');
    const restriction_val = $(ms).val();

    const diet_label_selector = "label[for=diet-textarea-bowler-" + id + "]";
    // const diet_textarea_selector = "textarea[for=bowlers_" + id + "_diet]";

    const diet_restriction_div = "diet_restriction_div_" + id;
    const diet_requirement_div = "diet_requirement_div_" + id;
    const diet_textarea_id = "diet-textarea-bowler-" + id;

    const diet_restriction_selector = "bowlers_" + id + "_diet_restriction";
    const diet_restriction_selection = $("#" + diet_restriction_selector).val();
    console.log("diet_restriction_selection = " + diet_restriction_selection);

    if (restriction_val != 'Other') {
        $("#"+diet_requirement_div).addClass('hidden');
        // document.getElementById(diet_textarea_id).innerText = "";
        $("#"+diet_textarea_id).val('');

        // TODO: hide the radio buttons

        // TODO: unselect all radio buttons
        // TODO: hide the "diet requirements" box
        // $("#"+diet_label_selector).addClass('disabled-label');
        // // $(diet_textarea_selector).prop('disabled', true);
        // document.getElementById(diet_textarea_id).innerText = "";
        // document.getElementById(diet_textarea_id).readOnly = true;
    } else {
        $("#"+diet_requirement_div).removeClass('hidden');



        // $("#"+diet_requirement_div).removeClass('hidden');

        // TODO: which radio button is selected, if none that's OK since we might be unhiding it
        // TODO: Show the radio buttons



        // TODO: If "Other" radio button selected then show "diet requirements" box
        // $("#"+diet_label_selector).removeClass('disabled-label');
        // // $(diet_textarea_selector).prop('disabled', false);
        // document.getElementById(diet_textarea_id).readOnly = false;

        // TODO: If "other" radio button not selected then hide the "diet requirements" box
    }
}

// function toggleShirt(ms) {
//     const id = $(ms).attr('id').replace(/bowlers_/, '').replace(/_shirt_size/, '');
//
//     const shirtSelector = "#bowlers_" + id + "_shirt_size option:selected";
//     const selectedShirtSize = $(shirtSelector).text();
//
//     const count_selector = "#bowlers_" + id + "_shirt_count";
//     const count_selector_first_option = count_selector + " option:eq(0)";
//     const label_for_count_selector = "label[for=bowlers_" + id + "_shirt_count]";
//
//     if (selectedShirtSize == 'None') {
//         $(label_for_count_selector).removeClass('red-error-label');
//         $(label_for_count_selector).addClass('disabled-label');
//         $(count_selector).prop('disabled', true);
//         $(count_selector_first_option).text('');
//         $(count_selector_first_option).prop('selected', true);
//     } else {
//         $(label_for_count_selector).removeClass('disabled-label');
//         $(count_selector_first_option).text('-- Select How Many --');
//         $(count_selector).prop('disabled', false);
//     }
// }

function toggleIgboLeagueMember(idx, cb) {
    let id = -1;
    if (idx == null) {
        id = $(cb).attr('id').replace(/bowlers_/, '').replace(/_igbo_league_member/, '');
    } else {
        id = idx;
    }

    const igbo_league_member_checkbox_id = "#bowlers_" + id + "_igbo_league_member";
    const igboLeagueMemberIsChecked = $(igbo_league_member_checkbox_id).is(':checked');


    const label_for_count_selector = "label[for=bowlers_" + id + "_scratch_division]";
    const division_selector = "#bowlers_" + id + "_scratch_division";
    const division_selector_first_option = division_selector + " option:eq(0)";

    if (igboLeagueMemberIsChecked) {
        $(label_for_count_selector).removeClass('disabled-label');
        $(division_selector_first_option).text('-- Select Your Division --');
        $(division_selector).prop('disabled', false);
    } else {
        $(label_for_count_selector).removeClass("red-error-label");
        $(label_for_count_selector).addClass('disabled-label');
        $(division_selector).prop('disabled', true);
        $(division_selector_first_option).text('');
        $(division_selector_first_option).prop('selected', true);
    }


}

function toggleScratch(idx, cb) {
    let id = -1;
    if (idx == null) {
        id = $(cb).attr('id').replace(/bowlers_/, '').replace(/_scratch_masters/, '').replace(/_optional_scratch/, '');
    } else {
        id = idx;
    }

    const scratch_masters_checkbox_id = "#bowlers_" + id + "_scratch_masters";
    const scratchMastersIsChecked = $(scratch_masters_checkbox_id).is(':checked');

    const optional_scratch_checkbox_id = "#bowlers_" + id + "_optional_scratch";
    const optionalScratchIsChecked = $(optional_scratch_checkbox_id).is(':checked');

    const label_for_count_selector = "label[for=bowlers_" + id + "_scratch_division]";
    const division_selector = "#bowlers_" + id + "_scratch_division";
    const division_selector_first_option = division_selector + " option:eq(0)";

    if (scratchMastersIsChecked || optionalScratchIsChecked) {
        $(label_for_count_selector).removeClass('disabled-label');
        $(division_selector_first_option).text('-- Select Your Division --');
        $(division_selector).prop('disabled', false);
    } else {
        $(label_for_count_selector).removeClass("red-error-label");
        $(label_for_count_selector).addClass('disabled-label');
        $(division_selector).prop('disabled', true);
        $(division_selector_first_option).text('');
        $(division_selector_first_option).prop('selected', true);
    }
}

function toggleFreeEntry(ms) {
    const id = $(ms).attr('id').replace(/bowlers_/, '').replace(/_free_entry/, '');
    const free_entry_val = $(ms).val();

    const free_entry_code_label_selector = "label[for=bowlers_" + id + "_free_entry_code]";
    const free_entry_code_text_selector = "bowlers_" + id + "_free_entry_code";
    const free_entry_code_div = "free_entry_code_div_" + id;

    if (free_entry_val == '' || free_entry_val == 'false') {
        $("#"+free_entry_code_div).addClass('hidden');
        $(free_entry_code_label_selector).removeClass('red-error-label');
        $(free_entry_code_label_selector).addClass('disabled-label');
        $(free_entry_code_text_selector).prop('disabled', true);
        document.getElementById(free_entry_code_text_selector).readOnly = true;
        const selector = 'input[type="text"][id$="bowlers_' + id + '_free_entry_code"]';
        $(selector).val('');
    } else {
        $("#"+free_entry_code_div).removeClass('hidden');
        $(free_entry_code_label_selector).removeClass('disabled-label');
        $(free_entry_code_text_selector).prop('disabled', false);
        document.getElementById(free_entry_code_text_selector).readOnly = false;
    }
}

function toggleNonParticipant(cb) {
    const id = $(cb).attr('id').replace(/bowlers_/, '').replace(/_non_participant/, '');

    const isChecked = $(cb).is(':checked');

    // let bowler = parseInt(id, 10) + 1;
    const sectionSelector = "#non-participant-" + id;

    if (isChecked) {
        $(sectionSelector).removeClass("hidden");
    } else {
        clearNonParticipantFields(id, "first_name");
        clearNonParticipantFields(id, "last_name");
        clearNonParticipantFields(id, "city");
        clearNonParticipantFields(id, "state");
        $(sectionSelector).addClass("hidden");
    }
}

function clearBowlerLeagueFields(id, field_name) {
    const fieldNameSelector = "#" + field_name + "_" + id + "_b" + id;
    console.log("fieldNameSelector = " + fieldNameSelector);
    $(fieldNameSelector).val("");
    const labelForField = "label[for=" + field_name + "_" + id + "_b" + id + "]";
    console.log("labelForField = " + labelForField);
    $(labelForField).removeClass('red-error-label');
}

function clearNonParticipantFields(id, field_name) {
    const fieldNameSelector = 'input[type="text"][id="bowlers_' + id + '_non_participant_' + field_name + '"]';
    $(fieldNameSelector).val("");
    const labelForField = "label[for=bowlers_" + id + "_non_participant_" + field_name + "]";
    $(labelForField).removeClass('red-error-label');
}

function shiftPreferenceRequired(required) {
    $('input[type="radio"][id^="team_shift_preference_team_shift_preference_"]').each(function () {
        if (required) {
            this.setAttribute('required', '');
        } else {
            this.removeAttribute('required');
        }
    });
    $('input[type="radio"][id^="sd_shift_preference_sd_shift_preference_"]').each(function () {
        if (required) {
            this.setAttribute('required', '');
        } else {
            this.removeAttribute('required');
        }
    });
}
